import {
    postRequest,
    getRequest
} from "./request";
// 推荐位
export const position = {
    detail(data, fun) {
        return postRequest("/api/position.content/detail", data, false)
    },
    lists(data, fun) {
        return postRequest("/api/position.content/lists", data, false)
    }, 
}
// 文章
export const article = {
    lists(data, fun) {
        return postRequest("/api/article/lists", data, false)
    },
    detail(data, fun) {
        return postRequest("/api/article/detail", data, false)
    },
    channel:{
        lists(data, fun) {
            return postRequest("/api/article.channel/lists", data, false)
        }
    }
  
}