//引入 axios 模块
import axios from "axios";
import Qs from "qs";
import vueMain from '@/main.js'
import {
  Toast,
  Notify,
  Dialog
} from 'vant'
// import store from '@/store/index'
// import { Toast, Notify } from 'vant' /api
//判断环境配置
// token验证接口
const baseUrl = process.env.NODE_ENV === "development" ? "/api" : "https://jxshjt.jxscloudmedia.com/index.php"; //   /api本地开发环境下的接口地址   '' 里面填写上线之后的接口地址

axios.defaults.withCredentials = true; //允许携带cookie  axios默认不携带

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
// 创建一个axios实例

const intance = axios.create({
  baseURL: baseUrl,
  timeout: 50000 //请求响应时间
});

// 请求拦截
intance.interceptors.request.use(
  config => {
    // 在请求之前要做的事
    // Toast.loading({
    //     message: '数据请求中',
    //     forbidClick: true,
    //     duration: 0
    // })
    config.data = Qs.stringify(config.data);
    return config;
  },
  error => {
    //请求拦截失败之后
    return Promise.reject(error);
  }
);
// 响应拦截
intance.interceptors.response.use(
  response => {
    // Toast.clear();
    // 响应数据要做的事
    if (response.status >= 100 && response.status < 200) {
      //1xx：表示临时响应并需要请求者继续执行操作的状态代码
      Notify({
        type: 'danger',
        message: `${response.status} ${response.statusText}`
      });
    }
    if (response.status >= 200 && response.status < 300) {
      //2xx：表示成功处理了请求的状态代码
      // Notify({ type: 'danger', message: `${response.status} ${response.statusText}` });
    }
    if (response.status >= 300 && response.status < 400) {
      //3xx：表示要完成请求，需要进一步操作。通常，这些状态代码用来重定向
      Notify({
        type: 'danger',
        message: `${response.status} ${response.statusText}`
      });
    }
    if (response.status >= 400 && response.status < 500) {
      //4xx：这些状态代码表示请求可能出错，妨碍了服务器的处理
      Notify({
        type: 'danger',
        message: `${response.status} ${response.statusText}`
      });

    }
    if (response.status >= 500) {
      //5xx这些状态代码表示服务器在尝试处理请求时发生内部错误。这些错误可能是服务器本身的错误，而不是请求出错
      Notify({
        type: 'danger',
        message: `${response.status} ${response.statusText}`
      });

    }

    return response.data;
  },
  error => {
    // 响应失败之后要做的事

    // //('响应拦截失败');
    return Promise.reject(error);
  }
);
// get请求
export function getRequest(url, data = {}) {
  return intance.get(url, {
    params: data
  });
}
// post请求
export function postRequest(url, data = {}, blon) {
  // 验证Token
  let blons = (blon == undefined ? true : blon) && true
  if (blons) {
    //获取localStroage值
    var token = localStorage.getItem("token")
    let obj = {
      token: token
    }
    return intance.post('/api/Member/checkToken', obj).then(res => {
      if (res.code == 1) {
        data.token = obj.token;
        return intance.post(url, data)
      } else {
        Dialog.confirm({
          title: '登录',
          message: '您还没未登录或登录已过期~',
        }).then(() => {
          vueMain.$router.push('/login')
          return false
        }).catch(() => {
          // 取消登录
          vueMain.$router.push('/index');
          location.reload();
        })
      }
    })
  } else {
    // 不需要验证token
    return intance.post(url, data);
  }
}
/**
 *
 * 图片上传
 * **/

// formdata 请求  文件流
const upLoadImage = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

// 响应拦截
upLoadImage.interceptors.response.use(
  response => {
    // 响应数据要做的事
    // //('响应拦截成功');
    return response.data;
  },
  error => {
    // 响应失败之后要做的事
    // //('响应拦截失败');
    return Promise.reject(error);
  }
);

export const upLoadRequest = (url, data = {}) => {
  return upLoadImage.post(url, data);
};